import * as React from "react"
import LogoWhite from "../images/runic-logo.svg"
import Header from "../component/layout/header";


const LinkClass = "text-pop-stone p-1 md:p-4 hover:text-pop-aqua font-light"

const IndexPage = () => {
    return (
        <>
            <div className={"container mx-auto min-h-screen"}>
                <Header />
                <div>
                    <div className={"text-center text-pop-stone"}>
                        <div
                            className={"sm:text-4xl text-2xl text-pop-stone font-bold pb-6 lg:pt-28 pt-16"}>
                            <div>Thank you for your submission</div>
                        </div>
                        <div className={"font-thin md:text-2xl sm:text-xl text-lg pb-12"}>
                            We'll be in touch soon
                        </div>
                    </div>
                </div>
                <div className={"absolute bottom-0 left-0 right-0"}>
                    <div className={"container mx-auto"}>
                        <div
                            className={"lg:pt-40 pt-12 text-pop-stone font-extralight sm:text-sm text-xs sm:px-12 px-4 lg:px-0"}>
                            <LogoWhite className={"sm:w-[144px] w-[100px]"}/>
                            <div className={"md:grid md:grid-cols-2 flex pt-12 pb-6"}>
                                <div className={"grow sm:pt-0 pt-1"}>
                                    <div>
                          <span>
                              Copyright 2023 Runic. All Rights Reserved.
                          </span>
                                    </div>
                                </div>
                                <div className={"text-right"}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const Head = () => (
    <>
        <title>Runic AI</title>
    </>
)

export default IndexPage
